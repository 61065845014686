// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyC5gpiywHAjqcdS-EmtnYWtWBL_vTTvMCw",
  authDomain: "habib-e-commerce-7eb87.firebaseapp.com",
  projectId: "habib-e-commerce-7eb87",
  storageBucket: "habib-e-commerce-7eb87.appspot.com",
  messagingSenderId: "293952856411",
  appId: "1:293952856411:web:1d6bfd0cc1374ee0e6ba7e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
