import { useState } from "react";
import DiscountBanner from "../HomeTwo/DiscountBanner";
import Drawer from "../Mobile/Drawer";
import Footer from "./Footers/FooterTwo";
import HeaderTwo from "./Headers/HeaderTwo";

export default function LayoutHomeTwo({ children, childrenClasses }) {
  const [drawer, setDrawer] = useState(false);
  return (
    <>
      <Drawer open={drawer} action={() => setDrawer(!drawer)} />
      <div className="w-full overflow-x-hidden">
        <HeaderTwo drawerAction={() => setDrawer(!drawer)} />
        <div
          className={`w-full  ${
            childrenClasses || "pt-0 lg:pt-[25px] pb-[60px]"
          }`}
        >
          {children && children}
        </div>
        <DiscountBanner />

        <Footer />
      </div>
    </>
  );
}
